<template>
  <div class="mt-5 fidelitycard-view tigros-card-container">
    <h1 class="text-center mt-2">{{ $t("Card.title") }}</h1>
    <p class="text-center">
      {{ $t("Card.descr") }}
    </p>
    <div v-show="this.cart.user.fidelityCard">
      <v-row col="12">
        <v-col
          ><div class="fidelity-code">
            <span class="font-weight-normal">
              {{ $t("Card.fidelityDescr") }}</span
            >
            {{ this.cart.user.fidelityCard }}
          </div></v-col
        >
      </v-row>
      <v-row>
        <v-col>
          <div class="fidelity-logo-container">
            <img
              v-if="isCartaOroUser"
              src="/img_layout/card/tigros-card-oro.png"
            />
            <img
              v-else-if="isCartaDipendentiUser"
              src="/img_layout/card/tigros_aziendale.png"
            />
            <!-- <img
              v-else-if="isCartaConvenzionataUser"
              src="/img_layout/card/card.png"
            /> -->
            <img v-else src="/img_layout/card/tigros_card.png" />
          </div>
          <div
            class="fidelity-ean-container"
            :class="{
              'is-oro': isCartaOroUser == true,
              'is-dipendenti': isCartaDipendentiUser == true
            }"
          >
            <barcode
              v-bind:value="this.cart.user.fidelityCard"
              class="fidelity-ean"
            >
              Show this if the rendering fails.
            </barcode>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col> </v-col>
      </v-row>
    </div>
    <div class="pt-5" v-if="this.cart.user && !this.cart.user.fidelityCard">
      <v-btn @click="regenerateCard" color="primary" large block depressed>
        Richiedi una Card
      </v-btn>
    </div>
  </div>
</template>
<style lang="scss">
.tigros-card-container {
  .fidelity-code {
    border: 1px solid #ccc;
    background-color: #f8f8f8;
    color: $primary;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding: 10px;

    * {
      color: $primary;
    }
    span {
      font-weight: normal;
    }
  }

  .col {
    text-align: center;
    img {
      width: 100%;
    }
  }

  .fidelity-ean-container,
  .fidelity-logo-container {
    max-width: 500px;
  }
  .fidelity-ean-container {
    position: relative;
    border: 1px solid #ddd;
    background-color: $secondary;
    color: #ffd800;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin: auto;
    border-radius: 10px;
    width: 480px;
    height: 312px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 100%;
      height: auto;
    }
    &.is-oro {
      background-color: #cb9d47;
    }
    &.is-dipendenti {
      background-color: #babcbe;
    }

    .fidelity-ean {
      position: absolute;
      width: calc(100% - 40px);
      height: calc(100% - 50px);
      top: 0px;
      left: 0px;
      background: #ffffff;
      margin: 20px;
      padding: 5%;
      resize: both;
      @media (max-width: 320px) {
        padding: 0px !important;
        left: -5px;
        background: none;
      }
      @media #{map-get($display-breakpoints, 'sm-only')} {
        padding-top: 13%;
        position: relative !important;
      }
      @media #{map-get($display-breakpoints, 'xs-only')} {
        position: relative !important;
        height: calc(100% - 45px);
      }
      .vue-barcode-element {
        width: 100%;
        height: 100%;
        margin-top: 10px;
      }
    }
  }

  .fidelity-logo-container {
    padding: 0px;
    margin: auto;
  }
}
</style>
<script>
import VueBarcode from "vue-barcode";

import tigrosMixins from "@/mixins/tigrosMixins";

import SecurityService from "~/service/securityService";
import tigrosRegistrationService from "@/service/tigrosRegistrationService";

export default {
  name: "TigrosCard",
  data() {
    return {
      fidelityCard: ""
    };
  },
  components: {
    barcode: VueBarcode
  },
  mixins: [tigrosMixins],
  methods: {
    regenerateCard() {
      tigrosRegistrationService.regenerateCard(
        () =>
          function() {
            SecurityService.refreshLogin();
          }
      );
    }
  },
  created() {}
};
</script>
